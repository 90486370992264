import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { EventSystem } from '../../../../events/EventSystem';
import PicklistUpdatedEvent from '../../../../events/PicklistUpdatedEvent';
import { CustomSourceKeyAssociciatedClients, PickListItem } from '../../../../models/Picklist';
import { Translations } from '../../../../models/Translation';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import { currentLayoutAtom } from '../../../../recoil/atoms/Layout';
import ClientService from '../../../../services/ClientService';
import PickListService from '../../../../services/PickListService';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { Option } from '../../../Option';
import PickListModal from '../../../shared/pick-list/PickListModal';
import { PickListItemType } from '../../../shared/pick-list/PickListTypes';
import WordTag from '../../../shared/tags/WordTag';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import { SupportedLanguage } from '../../../../types/Languages';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

type PickListActionData = {
  sourceType: string;
  buttonText: string;
};

type PickListActionProps = ActionBaseProps<Option<string, string>[], PickListActionData>;

const PickListAction: FC<PickListActionProps> = (props) => {
  const { response, data, required } = props;
  const { sourceType, buttonText, question, description } = data;
  const { onAnswer, readOnly } = useFormAction(props);
  const [showPickList, setShowPickList] = useState(false);
  const [inputTags, setInputTags] = useState<Option<string, string>[]>(response || []);
  const [picklistId, setPicklistId] = useState('');
  const [pickListItems, setPickListItems] = useState<PickListItem[]>([]);
  const [picklistOptions, setPicklistOptions] = useState<PickListItemType[]>([]);
  const [picklistLoading, setPicklistLoading] = useState(true);
  const [picklistSetupError, setPicklistSetupError] = useState(false);
  const currentLayout = useRecoilValue(currentLayoutAtom);
  const [isDynamic, setIsDynamic] = useState(false);
  const { t } = useTranslation(['activity-type']);

  const answerBoxRef = useRef<HTMLDivElement>(null);
  const [answerBoxHasBorder, setAnswerBoxHasBorder] = useState(false);

  const client = useRecoilValue(currentClientAtom);

  const { publicFormId } = useParams();

  useEffect(() => {
    setInputTags(response || []);
  }, [response]);

  const getAffiliatedEntries = useCallback(async () => {
    if (!client) {
      setPicklistOptions([
        { id: 'A', text: 'Client A', value: 'Client A' },
        { id: 'B', text: 'Client B', value: 'Client B' },
        { id: 'C', text: 'Client C', value: 'Client C' },
      ]);
      setPicklistLoading(false);
      return Promise.resolve();
    }

    const res = await ClientService.getAffiliates(client.id || '');
    setPicklistId(CustomSourceKeyAssociciatedClients);
    setPicklistOptions(
      res.data.map((client) => ({
        id: client.id,
        text: client.name,
        value: '',
      })),
    );
    setPicklistLoading(false);
  }, [client]);

  const getSystemPicklistEntries = useCallback(async () => {
    const res = await PickListService.getPickList(sourceType, false, publicFormId);
    setIsDynamic(!res.data.isSystem);
    setPicklistId(res.data.id);
    setPickListItems(res.data.items);
    createPickListOptions(res.data.items);
    setPicklistLoading(false);
  }, [sourceType, publicFormId]);

  const fetchPicklistData = useCallback(async () => {
    if (!sourceType) {
      setPicklistSetupError(true);
      return Promise.resolve();
    }
    setPicklistLoading(true);
    setPicklistOptions([]);
    switch (sourceType) {
      case CustomSourceKeyAssociciatedClients:
        await getAffiliatedEntries();
        break;
      default:
        await getSystemPicklistEntries();
    }
  }, [getAffiliatedEntries, getSystemPicklistEntries, sourceType]);

  useEffect(() => {
    // defer loading data until we open the picklist
    if (picklistOptions.length === 0 && showPickList) {
      fetchPicklistData();
    }
  }, [fetchPicklistData, picklistOptions.length, showPickList]);

  const picklistAddCustom = (translations: Translations) => {
    return PickListService.addCustomItem(client?.id || '', picklistId, translations).then(async (res) => {
      await fetchPicklistData();
      return res.data.id;
    });
  };

  const createPickListOptions = (items: PickListItem[], code?: SupportedLanguage) => {
    setPicklistOptions(
      items.map((item) => {
        return {
          id: item.id,
          text: LanguageUtils.getTranslation('name', item.translations, code),
          subText: LanguageUtils.getTranslation('description', item.translations, code),
          value: LanguageUtils.getTranslation('description', item.translations, code),
          isCustom: item.isCustom,
          translations: item.translations,
          isDisabled: item.isDisabled,
        };
      }),
    );
  };

  useEffect(() => {
    const handler = (evt: PicklistUpdatedEvent) => {
      if (evt.sourceType === sourceType) {
        fetchPicklistData();
      }
    };

    EventSystem.listen('picklist-updated', handler);
    return () => EventSystem.stopListening('picklist-updated', handler);
  }, [fetchPicklistData, sourceType]);

  const onPicklistResponse = (response: Option<string, string>[]) => {
    setInputTags(response);
    onAnswer(response);
  };

  const onTagRemoveClicked = (tagId: string): void => {
    const tags = inputTags.filter((tagEl) => tagEl.id !== tagId);
    setInputTags(tags);
    onAnswer(tags);
  };

  const selectedItems = useMemo(() => inputTags.map((tag) => tag.id), [inputTags]);

  useEffect(() => {
    if (!answerBoxRef.current) {
      return;
    }

    const hasScrollbar = answerBoxRef.current.scrollHeight > answerBoxRef.current.clientHeight;
    setAnswerBoxHasBorder(hasScrollbar);
  }, [response, readOnly]);

  useEffect(
    function langaugeChanged() {
      const handler = (code: SupportedLanguage) => {
        if (sourceType && sourceType !== CustomSourceKeyAssociciatedClients) {
          createPickListOptions(pickListItems, code);
        }
      };
      EventSystem.listen('language-changed', handler);
      return () => {
        EventSystem.stopListening('language-changed', handler);
      };
    },
    [pickListItems, sourceType],
  );

  return (
    <div data-cy="picklist-action">
      <ActionTitleDescription required={required} {...data} />

      <div
        ref={answerBoxRef}
        className={`my-2 flex max-h-32 flex-row flex-wrap justify-items-start overflow-y-auto ${
          answerBoxHasBorder ? 'border-gray-6 border-8 p-2' : ''
        } rounded-md empty:border-0`}
      >
        {inputTags.map((tag) => (
          <WordTag
            key={tag.id}
            tag={{ text: tag.text, id: tag.id, value: tag.id }}
            tooltip={tag.value}
            disabled={readOnly}
            removeTag={onTagRemoveClicked}
          />
        ))}
      </div>

      <button
        data-cy="picklist-open"
        onClick={() => setShowPickList(true)}
        className={`${!readOnly ? 'cursor-pointer underline' : 'cursor-not-allowed opacity-50'}`}
        style={{ pointerEvents: !readOnly ? 'all' : 'none' }}
      >
        {buttonText || t('activity-type:resource-pick-list.buttons.open-picker')}
      </button>
      <PickListModal
        picklistSourceType={sourceType}
        setupError={picklistSetupError}
        title={question}
        subTitle={description || ''}
        items={picklistOptions}
        loading={picklistLoading}
        open={showPickList}
        selectedItems={selectedItems}
        onClose={() => setShowPickList(false)}
        onComplete={(response) => onPicklistResponse(response)}
        addCustomValue={picklistAddCustom}
        showAddNew={currentLayout !== 'Minimal' && !picklistId.startsWith('\0') && isDynamic}
      />
    </div>
  );
};

export default PickListAction;
